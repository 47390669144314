import React from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { getTemplatesDetails } from "../api";
import { MainSpinner, TemplateDesignPin } from "../compotenents";
import { FaHouse } from "react-icons/fa6";

import {
  BiFolderPlus,
  BiHeart,
  BiSolidFolderPlus,
  BiSolidHeart,
} from "react-icons/bi";
import useUser from "../hooks/useUser";
import { saveToCollections } from "../api";
import { saveToFavorites } from "../api";
import useTemplates from "../hooks/useTemplates";
import { AnimatePresence } from "framer-motion";

const TemplateDesignPinDetails = () => {
  const { templateID } = useParams();
  const { data, isError, isLoading, refetch } = useQuery(
    ["template", templateID],
    () => getTemplatesDetails(templateID)
  );

  const { data: user, refetch: userRefetch } = useUser();

  const addToCollection = async (e) => {
    e.stopPropagation();
    await saveToCollections(user, data);
    userRefetch();
    refetch();
  };
  const {
    data: templates,
    refetch: temp_refetch,
    isLoading: temp_isLoading,
  } = useTemplates();

  const addToFavorite = async (e) => {
    e.stopPropagation();
    await saveToFavorites(user, data);
    temp_refetch();
    refetch();
  };

  if (isLoading) return <MainSpinner />;
  if (isError) {
    return (
      <div className="w-full h-[60vh] flex flex-col items-center justify-center">
        <p className="text-lg text-textDark font-semibold">
          Erreur en essayéyant de récuppérer les données... s'il vous plait
          réqctuqliser votre page
        </p>
      </div>
    );
  }
  return (
    <div className="w-full flex items-center justify-start flex-col px-4 py-12">
      {/* bread crump */}
      <div className="w-full flex items-center pb-8 gap-2">
        <Link to={"/"} className="flex items-center gap-2 text-textPrimary">
          <FaHouse />
          Home
        </Link>
        <p>/</p>
        <p>{data.name}</p>
      </div>

      {/* design main section layout */}
      <div className="w-full grid grid-cols-1 lg:grid-cols-12">
        {/* leaft section */}
        <div className="col-span-1 lg:col-span-8 flex flex-col items-start justify-start gap-4">
          {/* load the template image */}
          <img
            className="w-full h-auto object-contain rounded-md"
            src={data?.imageURL}
            alt=""
          />

          {/* title and other section */}
          <div className="w-full flex  flex-col items-start justify-start gap-2">
            {/* title section */}
            <div className="w-full flex items-center justify-between">
              {/* display title section */}
              <p className="text-base text-textPrimary font-semibold">
                {data?.title}
              </p>
              {/* likes */}
              {data?.favorites?.length > 0 && (
                <div className="flex items-center justify-center gap-1">
                  <BiSolidHeart className="text-base text-red-500" />
                  <p className="text-base text-textPrimary font-semibold">
                    {data?.favorites?.length} likes
                  </p>
                </div>
              )}
            </div>
            {/* collections and fovoris options */}
            {user && (
              <div className="flex items-center justify-center gap-3">
                {/* collection */}
                {user?.collections?.includes(data?._id) ? (
                  <React.Fragment>
                    <div
                      onClick={addToCollection}
                      className="flex items-center justify-center px-4 py-2 rounded-md border border-gray-300  gap-2 hover:bg-gray-200 cursor-pointer"
                    >
                      <BiSolidFolderPlus className="text-base text-textPrimary" />
                      <p className="text-sm text-textPrimary whitespace-nowrap">
                        Retirer de la collections
                      </p>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div
                      onClick={addToCollection}
                      className="flex items-center justify-center px-4 py-2 rounded-md border border-gray-300  gap-2 hover:bg-gray-200 cursor-pointer"
                    >
                      <BiFolderPlus className="text-base text-textPrimary" />
                      <p className="text-sm text-textPrimary whitespace-nowrap">
                        Ajouter de la collections
                      </p>
                    </div>
                  </React.Fragment>
                )}
                {/* favoris */}
                {data?.favorites?.includes(user?.uid) ? (
                  <React.Fragment>
                    <div
                      onClick={addToFavorite}
                      className="flex items-center justify-center px-4 py-2 rounded-md border border-gray-300  gap-2 hover:bg-gray-200 cursor-pointer"
                    >
                      <BiSolidHeart className="text-base text-textPrimary" />
                      <p className="text-sm text-textPrimary whitespace-nowrap">
                        Retirer des favoris
                      </p>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div
                      onClick={addToFavorite}
                      className="flex items-center justify-center px-4 py-2 rounded-md border border-gray-300  gap-2 hover:bg-gray-200 cursor-pointer"
                    >
                      <BiHeart className="text-base text-textPrimary" />
                      <p className="text-sm text-textPrimary whitespace-nowrap">
                        Ajouter aux favoris
                      </p>
                    </div>
                  </React.Fragment>
                )}
                {/* end favoris */}
              </div>
            )}
          </div>
        </div>
        {/* right section */}
        <div className="col-span-1 lg:col-span-4 w-full flex flex-col items-center justify-start px-3 gap-6">
          {/* en savoir plus */}
          <div
            className="w-full h-72 bg-blue-500 rounded-md overflow-hidden relative"
            style={{
              background:
                "url(https://cdn.pixabay.com/photo/2017/07/03/20/17/colorful-2468874_1280.jpg)",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <div className="absolute inset-0 flex items-center justify-center bg-[rgba(0,0,0,.4)]">
              <Link
                to={"/"}
                className="px-4 py-2 rounded-md border-2 border-gray-50 text-white"
              >
                En Savoir Plus
              </Link>
            </div>
          </div>

          {/* fin en savoir plus */}

          {/* modifier le template */}
          {user && (
            <Link
              className="w-full px-4  py-3 rounded-md flex items-center justify-center bg-emerald-500 cursor-pointer"
              to={`/resume/${data?.name}?templateId=${templateID}`}
            >
              <p className="text-white font-semiboldt text-lg">
                Modifier cette modèle de cv
              </p>
            </Link>
          )}
          {/* fin modifier le template */}

          {/* tags */}
          <div className="w-full flex items-center justify-start gap-2  flex-wrap">
            {data?.tags?.map((tag, index) => (
              <p
                className="text-xs border border-gray-300 px-2 py-1 rounded-md whitespace-nowrap"
                key={index}
              >
                {tag}
              </p>
            ))}
          </div>
          {/* fin tags */}
        </div>

        {/* similar template */}
        {templates?.filter((temp) => temp._id != data?._id)?.length > 0 && (
          <div className="w-full py-8 flex flex-col items-start justify-start gap-4">
            <p className="text-lg font-semibold text-textDark whitespace-nowrap">
              On a aussi d'autre modèles qui pourer peur etre vous interesser...
            </p>
            <div className="w-full grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 2xl:grid-cols-4 gap-2">
              <React.Fragment>
                <AnimatePresence>
                  {templates
                    ?.filter((temp) => temp._id != data?._id)
                    .map((template, index) => (
                      <TemplateDesignPin
                        key={template?._id}
                        data={template}
                        index={index}
                      />
                    ))}
                </AnimatePresence>
              </React.Fragment>
            </div>
          </div>
        )}
        {/* fin similar template */}
      </div>
    </div>
  );
};

export default TemplateDesignPinDetails;
