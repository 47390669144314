import React, { useEffect } from 'react'
import { Logo } from "../assets";
import { Footer } from '../containers';
import { AuthButtonWitchProvider, MainSpinner } from "../compotenents";
import {  FaGoogle, FaGithub} from "react-icons/fa6";
import useUser from '../hooks/useUser';
import { useNavigate } from 'react-router-dom';

const Authentification = () => {

  const {data, isLoading, isError} = useUser();

  const navigate = useNavigate();

  useEffect(()=>{
    if (!isLoading && data) {
      navigate("/", { replace: true})
    }
  },[isLoading, data, navigate]) 

  if (isLoading) {
    return <MainSpinner />;
  }

  return (
    <div className="auth-section">

      {/* top section */}
      <img src={Logo} className="w-12 h-auto object-contain" alt="" />

      {/* main section */}
      <div className="w-full flex flex-1  flex-col items-center justify-center  gap-6">
        <h1 className="text-3xl lg:text-4xl text-blue-700">Bienvenue a cvminutes.com</h1>
        <p className="text-base text-gray-600">Creer votre cv en quelque minutes</p>
        <h2 className="text-2xl text-gray-600">S'identifier</h2>
        <div className="w-full lg:w-96 rounded-md p-2 flex flex-col items-center justify-start gap-6">
            <AuthButtonWitchProvider Icon={FaGoogle} label={"S'identifier avec google"} provider={"GoogleAuthProvider"}/>
            <AuthButtonWitchProvider Icon={FaGithub} label={"S'identifier avec github"} provider={"GithubAuthProvider"}/>
        </div>
      </div>



      {/* footer section */}
      <Footer />

    </div>
  )
}

export default Authentification