import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../config/firebase.config";
import { toast } from "react-toastify";

export const getUserdDetail = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((userCred) => {
      if (userCred) {
        const userData = userCred.providerData[0];

        const unsubscribe = onSnapshot(
          doc(db, "users", userData?.uid),
          (_doc) => {
            if (_doc.exists()) {
              resolve(_doc.data());
            } else {
              setDoc(doc(db, "users", userData?.uid), userData).then(() => {
                resolve(userData);
              });
            }
          }
        );
        return unsubscribe;
      } else {
        reject(new Error("L'utilisateur ne s'est pas inscrite..."));
        console.log("log: L'utilisateur ne s'est pas inscrite...");
      }

      //make sure to unsubscribe from the listener to prevent the memory leaks
      unsubscribe();
    });
  });
};

//----------create template----
export const getTemplateDetailEditByUser = (uid, id) => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(
      doc(db, "users", uid, "resumes", id),
      (doc) => {
        resolve(doc.data());
      }
    );

    return unsubscribe;
  });
};
//------------end-create-template------------

export const getTemplates = () => {
  return new Promise((resolve, reject) => {
    const templateQuery = query(
      collection(db, "templates"),
      orderBy("timestamp", "asc")
    );
    const unsubscribe = onSnapshot(templateQuery, (querySnap) => {
      const templates = querySnap.docs.map((doc) => doc.data());
      resolve(templates);
    });
    return unsubscribe;
  });
};

export const saveToCollections = async (user, data) => {
  if (!user?.collections?.includes(data?._id)) {
    const docRef = doc(db, "users", user?.uid);
    await updateDoc(docRef, {
      collections: arrayUnion(data?._id),
    })
      .then(() => toast.success("ajouter au collections"))
      .catch((err) =>
        toast.error(`cette erreur c'est produit: ${err.message} `)
      );
  } else {
    const docRef = doc(db, "users", user?.uid);
    await updateDoc(docRef, {
      collections: arrayRemove(data?._id),
    })
      .then(() => toast.success("retirer du collections"))
      .catch((err) =>
        toast.error(`cette erreur c'est produit: ${err.message} `)
      );
  }
};
export const saveToFavorites = async (user, data) => {
  if (!data?.favorites?.includes(user?.uid)) {
    const docRef = doc(db, "templates", data?._id);
    await updateDoc(docRef, {
      favorites: arrayUnion(user?.uid),
    })
      .then(() => toast.success("ajouter au favorites"))
      .catch((err) =>
        toast.error(`cette erreur c'est produit: ${err.message} `)
      );
  } else {
    const docRef = doc(db, "templates", data?._id);
    await updateDoc(docRef, {
      favorites: arrayRemove(user?.uid),
    })
      .then(() => toast.success("retirer du favorites"))
      .catch((err) =>
        toast.error(`cette erreur c'est produit: ${err.message} `)
      );
  }
};

export const getTemplatesDetails = async (templateId) => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(doc(db, "templates", templateId), (doc) => {
      resolve(doc.data());
    });
    return unsubscribe;
  });
};

export const getSavedResumes = (uid) => {
  return new Promise((resolve, reject) => {
    const templateQuery = query(
      collection(db, "users", uid, "resumes"),
      orderBy("timeStamp", "asc")
    );
    const unsubscribe = onSnapshot(templateQuery, (querySnap) => {
      const templates = querySnap.docs.map((doc) => doc.data());
      resolve(templates);
    });
    return unsubscribe;
  });
};
