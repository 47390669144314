import { Template1, Template2, Template3 } from "../compotenents/designs";

export const adminIds = ["108916531253847872644", "101533859942611575387"];

export const initialTags = [
  "Ingénieur logiciel",
  "Analyste financier",
  "Développeur web",
  "Designer graphique",
  "Consultant en RH",
  "Marketing numérique",
  "Chef de projet",
  "Infirmière autorisée",
  "Analyste de données",
  "Technicien de support informatique",
  "Spécialiste en relations publiques",
];

export const FiltersData = [
  { id: "1", label: "Software Engineer", value: "Software Engineer" },
  { id: "2", label: "Front-end Developer", value: "Front-end Developer" },
  { id: "3", label: "Back-end Developer", value: "Back-end Developer" },
  { id: "4", label: "Full-stack Developer", value: "Full-stack Developer" },
  { id: "5", label: "Web Developer", value: "Web Developer" },
  { id: "6", label: "UI/UX Designer", value: "UI/UX Designer" },
  { id: "7", label: "Graphic Designer", value: "Graphic Designer" },
  { id: "8", label: "Data Scientist", value: "Data Scientist" },
  { id: "9", label: "Product Manager", value: "Product Manager" },
  { id: "10", label: "Project Manager", value: "Project Manager" },
  { id: "11", label: "Business Analyst", value: "Business Analyst" },
  { id: "12", label: "Marketing Manager", value: "Marketing Manager" },
  { id: "13", label: "Sales Representative", value: "Sales Representative" },
  {
    id: "14",
    label: "Customer Service Representative",
    value: "customer_service_representative",
  },
  { id: "15", label: "HR Manager", value: "hr_manager" },
  { id: "16", label: "Financial Analyst", value: "financial_analyst" },
  { id: "17", label: "Content Writer", value: "content_writer" },
  { id: "18", label: "Teacher/Educator", value: "teacher_educator" },
  {
    id: "19",
    label: "Healthcare Professional",
    value: "healthcare_professional",
  },
  { id: "20", label: "Legal Counsel", value: "legal_counsel" },
];

export const TemplatesData = [
  {
    id: `template-${Date.now()}`,
    name: "Template1",
    component: Template1,
  },
  {
    id: `template-${Date.now()}`,
    name: "Template2",
    component: Template2,
  },
  {
    id: `template-${Date.now()}`,
    name: "Template3",
    component: Template3,
  },
];
